<template>
  <div class="w-screen min-h-screen md:flex md:justify-center md:items-start lg:items-center dark:bg-gray-800 dark:text-gray-400">
    <div class="relative w-full md:max-w-xl bg-yellow-100 px-16 pb-10 md:rounded-lg shadow-md dark:bg-yellow-900">
      <div class="-ml-16 flex items-center">
        <img class="max-w-xs max-h-16 lg:max-h-24 xl:max-h-32 2xl:max-h-32" :src="companyLogoUrl" alt="Logo">
        <div class="flex flex-col">
          <h1 class="text-2xl font-semibold leading-6">e-Multiagencja</h1>
          <h2 class="text-xl text-gray-500 font-semibold dark:text-gray-300">resetowanie hasła</h2>
          <span class="text-xs opacity-50">krok 2 z 2</span>
        </div>
      </div>
      <hr class="border-gray-300 -mx-10 mb-2">
      <p v-if="!finished" class="font-semibold">Wpisz w pole obok nowe hasło do aplikacji oraz powtórz je w kolejnym polu.</p>
      <p v-else class="font-semibold"><span class="uppercase text-green-500">Hasło zostało poprawnie zmienione</span> <router-link :to="{ name: 'Login' }" class="tw--link whitespace-nowrap">Powrót do strony logowania</router-link></p>

      <form @submit.prevent="onSubmit">
        <div class="flex items-center">
          <div class="flex-grow">
            <input
              v-focus
              id="input-1"
              @focus="message = ''"
              ref="password"
              name="password"
              v-model="form.password"
              placeholder="nowe hasło"
              :type="showPass ? 'text' : 'password'"
              class="tw--form-big my-2 w-full"
              :class="{'border-red-500': message.length > 0 }"
              :disabled="isBusy || finished"
              required
            >
            <input
              id="input-2"
              @focus="message = ''"
              ref="passconf"
              v-model="form.passconf"
              placeholder="Powtórz nowe hasło"
              :type="showPass ? 'text' : 'password'"
              class="tw--form-big my-2 w-full"
              :class="{'border-red-500': message.length > 0 }"
              :disabled="isBusy || finished"
              required
            >
          </div>
          <div @click="showPass = !showPass" class="flex-shrink pl-4 opacity-70 hover:opacity-90 cursor-pointer"  :title="showPass ? 'Ukryj hasło' : 'Pokaż hasło'">
            <FontAwesomeIcon class="text-3xl" :icon="showPass ? ['far', 'eye'] : ['far', 'eye-slash']" />
          </div>
        </div>
        <div><b>Hasło powinno zawierać:</b>
          <ol class="text-sm list-decimal list-outside ml-4 text leading-4">
            <li>minimum 8 znaków</li>
            <li>co najmniej jedną wielką literę</li>
            <li>co najmniej jedną małą literę</li>
            <li>co najmniej jedną cyfrę lub jeden ze znaków specjalnych: <span class="whitespace-nowrap">[ <span class="opacity-50">!@#$%^&*()_+-=,.;':"[]{}</span> ]</span></li>
          </ol>
        </div>

        <button type="submit" class="tw--btn tw--btn-big w-full mt-2" :disabled="isBusy || finished">
          <span v-if="!isBusy">Resetuj hasło</span>
          <span v-else>Zapisuję...</span>
        </button>
        <div class="block text-right mb-4 mt-1">
          <router-link :to="{ name: 'Login' }" class="tw--link">Powrót do strony logowania</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService';

export default {
  name: 'PasswordReset',
  data() {
    return {
      form: {
        password: '',
        passconf: '',
      },
      message: '',
      isBusy: false,
      finished: false,
      showPass: false,
      welcome: {
        content: `Twoje hasło przetrzymujemy w bazie danych w formie zaszyfrowanej, więc nie możemy Ci go przypomnieć.
            Wpisz w pole obok nowe hasło do aplikacji oraz powtórz je w kolejnym polu.`,
        footer: '',
      },
      trollMessage: `Niepoprawne użycie funkcji!
        Brak kodu umożliwiającego reset hasła!
        Fakt nieprawidłowego użycia funkcji wraz z numerem IP użytkownika został odnotowany w logach bezpieczeństwa w celach kontrolnych.
        Kolejne próby będą skutkowały zablokowaniem numeru IP na serwerze, co uniemożliwi dostęp do aplikacji.`,
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    configParams() {
      return this.$store.getters.configParams;
    },
    companyLogoUrl() {
      let url = this.configParams.company_logo_l;
      if (url === undefined) {
        url = '';
      } else if (url === '') {
        url = '/images/logo/logotyp-emultiagencja-full.png';
      } else {
        url = `/${url}`;
      }
      return url;
    },
  },
  mounted() {
    AuthService.logout();
    this.$store.dispatch('logout');
    this.welcome.footer = this.configParams.company_short_name;
  },
  methods: {
    onSubmit() {
      this.isBusy = true;
      const formData = new FormData();
      formData.append('password', this.form.password);
      formData.append('passconf', this.form.passconf);
      AuthService.passwordReset(this.token, formData)
        .then(response => {
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: response.data.msg,
            time: 10,
          });
          this.isBusy = false;
          this.finished = true;
        })
        .catch(error => {
          if (error.response !== undefined) {
            this.message = error.response.data.msg;
            if (typeof errorMessages === 'object') {
              const messagesStr = this.errorMessages.join(', ');
              this.message = `Błędy: ${messagesStr}`;
            }
            this.$store.dispatch('notifications/add', {
              type: 'warning',
              message: this.message,
              time: 0,
            });
          } else {
            this.message = 'Wystapił nieznany błąd, być może serwer jest zajęty, spróbuj ponownie później';
            this.$store.dispatch('notifications/add', {
              type: 'danger',
              message: this.message,
              time: 0,
            });
          }
          this.isBusy = false;
        });
    },
  },
};
</script>
